// 关闭页面
export function closePage(){
    if ((navigator.userAgent.indexOf('Firefox') != -1 || navigator.userAgent.indexOf('Chrome') != -1) && !isWechat()) {
        console.log('Firefox或者Chrome浏览器');
        window.location.href = 'about:blank'
        window.close()
    } else if (isWechat()) {
        console.log('微信内置浏览器');
        closePageWx()
    } else {
        console.log('其他浏览器');
        window.opener = null
        window.open('', '_self')
        window.close()
    }
}


export function isWechat() {
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/micromessenger/i) == 'micromessenger') { // 判断是否在微信浏览器内
        return true;
    } else {
        return false;
    }
}


// 微信内置浏览器关闭
export function closePageWx() {
    // isWechat是我写的一个判断当前环境是否是微信内置浏览器 的方法
    if (!isWechat()) return // 非微信环境下，不做处理
    setTimeout(function() {
        if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
            WeixinJSBridge.invoke('closeWindow', {}, (res) => {
                console.log("res==============", res);

                if (res.errMsg == "close_window:ok") {
                    // 关闭成功的处理
                    console.log('关闭成功的处理')
                } else {
                    // 关闭失败的处理
                    console.log('关闭失败的处理')
                }
            });
        }
    }, 100);
}
