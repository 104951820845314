
<template>
  <div class="content">
<!--    电子签约服务协议与人脸信息处理规则.docx-->
    <File1></File1>

<!--    华融-征信授权书-->
    <File2></File2>
<!--    个人征信授权书.html-->
    <File3></File3>

<!--    个人担保授权征信查询书.html-->
    <File4></File4>
<!--    华融-委托担保申请书.html-->
    <File5></File5>
<!--    非学生身份承诺函-->
    <File6></File6>
  </div>
</template>

<script>
import File1 from "./file/file1.vue";
import File2 from "./file/file2.vue";
import File3 from "./file/file3.vue";
import File4 from "./file/file4.vue";
import File5 from "./file/file5.vue";
import File6 from "./file/file6.vue";

export default {
  components: {File6, File5, File4, File3, File2, File1},
  data(){
    return {

    }
  }
}
</script>
<style scoped>
.content{
  padding: 30px;
}
.content > div{
  margin-top: 100px;
}
</style>
