<template>
  <div style="padding: 20px">
    <van-form @submit="onSubmit">
      <van-field
          v-model="title"
          name="用户名"
          label="用户名"
          placeholder="用户名"
          disabled
      />
      <van-field
          v-model="subTitle"
          name="手机号"
          label="手机号"
          placeholder="手机号"
          disabled
      />
      <van-field
          v-model="corner"
          name="时间"
          label="时间"
          placeholder="时间"
          disabled
      />

      <van-field
          :disabled="flag !== '1'"
          v-model="content"
          rows="1"
          autosize
          label="内容"
          type="textarea"
          placeholder="请输入内容"
      />
      <div style="margin: 16px;" v-if="flag === '1'">
        <van-button round block type="primary" native-type="submit" @click="onSubmit">处理</van-button>
      </div>
      <div style="margin: 16px;" v-else>
        <van-button round block type="info" native-type="submit" @click="onBack">返回</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  name: 'TempDetail',
  data() {
    return {
      title: null,
      subTitle: null,
      content: null,
      corner: null,
      flag: 0
    }
  },
  created() {
    const params = this.$route.query
    if (params){
      this.title = params.title
      this.subTitle = params.subTitle
      this.content = params.content
      this.corner = params.corner
      this.flag = params.flag
    }
    console.log("flag==", this.flag)
  },
  methods: {
    onSubmit(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      setTimeout(() => {
        Toast.success('已处理成功');
        this.$router.push('/work')
      }, 1000)
    },
    onBack(){
      this.$router.push('/work')
    }
  }
}
</script>
